<template>
  <div
    class="markdown"
    v-html="markdown"
  />
</template>

<script>
import marked from 'marked'
import DOMPurify from 'dompurify'

export default {
  name: 'TextBlock',
  props: ['data'],
  data() {
    return {
      text: null,
      markdown: null,
    }
  },
  mounted() {
    (this.data.content !== undefined) 
      ? this.text = this.data.content
      : this.text = this.data
    
    this.markdown = DOMPurify.sanitize(
      marked(this.text, {
        headerIds: false,
        breaks: true
      })
    )
  },
  watch: {
    markdown(val) {
      if (val !== null) {
        this.$nextTick(() => {
          this.$emit('init')
        })
      }
    }
  }
}
</script>

<style>
.markdown ul li::before {
  content: "–";
  padding-right: .5rem;
}
</style>
