<template>
  <footer v-if="columns.length > 0" class="grid gap-6 grid-cols-8 pt-20 pb-5">
    <ul 
      v-for="(column, index) in columns" 
      :key="`${index}_column`"
      :class="[
        {'col-span-full md:col-span-3': index === 0},
        {'col-span-full md:col-span-2': index === 1},
        {'col-span-full md:col-span-2': index === 2},

      ]"
    >
      <template v-for="(item, index) in column">
        <ul 
          class="flex"
          v-if="item.__component === 'menu.socials'" 
          :key="`${index}-item`"
        >
          <li
            class="px-1 first:px-0 first:pr-1"
            v-for="(social, index) in item.socials" 
            :key="`${index}-social`"
          >
            <a :href="social.url" target="_blank" rel="nofollow">
              <unicon :name="social.type" :width="'16'" :height="'16'"></unicon>
            </a>
          </li>
        </ul>

        <text-block 
          v-if="item.__component === 'blocks.text'" 
          :data="item.content" 
          :key="`${index}-text`"
        />

        <li 
          v-if="item.__component === 'menu.link'"
          class="cursor-pointer" 
          :key="`${index}-link`"
        >
          <router-link :to="{ path: item.url }">
            {{ item.label }}
          </router-link>
        </li>

        <li 
          v-if="item.__component === 'menu.dropdown'"
          :key="`${index}-dropdown`"
        >
          {{ item.label }}
          <ul>
            <li 
              class="pl-3" 
              v-for="(link, index) in item.link" 
              :key="`${index}-dropdown_link`"
            >
              <router-link :to="{ path: link.url }">
                {{ link.label }}
              </router-link>
            </li>
          </ul>
        </li>

      </template>
    </ul>
  </footer>
</template>

<script>
import TextBlock from './blocks/TextBlock.vue'
export default {
  components: { TextBlock },
  name: 'Footer',
  data() {
    return {
      columns: []
    }
  },
  mounted() {
    this.$api
      .get('footer')
      .then(response => {
        let res = response.data
        this.columns.push(res.left_column)
        this.columns.push(res.center_column)
        this.columns.push(res.right_column)
      })
  }
}
</script>