import shop from '@/services/shop'

export default {
  state: {
    countries: []
  },
  mutations: {
    setCountries(state, countries) {
      state.countries = countries
    }
  },
  actions: {
    fetchCountries({commit}) {
      shop.getShippingCountries((countries, error) => {
        if (error) {
          throw error
        }
        commit('setCountries', countries)
      })
    },
  },
  getters: {
    countries(state) {
      return state.countries
    },
    inputCountries(state) {
      let obj = {}
      return state.countries.map(country => {
        return { 
          value: country.code,
          label: country.country
        }
      })
    }
  }
}