var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[(_vm.data !== null && _vm.collections.length > 0)?_c('div',{staticClass:"grid grid-cols-8 gap-6 pt-2 lg:pt-5"},[(_vm.data.introduction)?_c('div',{staticClass:"col-span-full row-auto md:row-span-2 md:col-span-5"},[_c('h1',{staticClass:"pt-0",domProps:{"innerHTML":_vm._s(_vm.data.introduction.title)}}),_c('video-block',{attrs:{"data":_vm.data.introduction}}),_c('text-block',{attrs:{"data":_vm.data.introduction}}),_c('ul',{staticClass:"flex flex-wrap pt-4"},[_c('li',{staticClass:"bg-black hover:bg-white text-white hover:text-black transition-colors rounded-full outline-none md:px-3 px-2 mr-2"},[_c('router-link',{attrs:{"to":{ name: 'Collection', params: { type: 'strandbeest' }}}},[_vm._v(" Genealogy ")])],1),_c('li'),_c('li',{staticClass:"bg-black hover:bg-white text-white hover:text-black transition-colors rounded-full outline-none md:px-3 px-2 mr-2"},[_c('router-link',{attrs:{"to":{ name: 'Collection', params: { type: 'evolution' }}}},[_vm._v(" Evolution ")])],1)]),(_vm.data.introduction.tags !== null)?_c('tags',{attrs:{"tags":_vm.data.introduction.tags}}):_vm._e()],1):_vm._e(),_vm._l((_vm.stories),function(story,index){return [_c('div',{key:("story-" + index),staticClass:"col-span-full row-auto md:col-span-3 md:row-span-1"},[_c('router-link',{staticClass:"no-underline",attrs:{"to":{
          name: 'Single',
          params: {
            type: 'news',
            slug: story.slug
          }
        }}},[(story.featured.title)?_c('h1',{staticClass:"pt-0",domProps:{"innerHTML":_vm._s(story.featured.title)}}):_c('h1',{staticClass:"pt-0",domProps:{"innerHTML":_vm._s(story.title)}}),_c('image-block',{attrs:{"data":story.featured,"figcaption":false}}),_c('text-block',{attrs:{"data":story.featured.excerpt}}),(story.tags !== null)?_c('tags',{attrs:{"tags":story.tags}}):_vm._e()],1)],1)]}),_vm._l((_vm.collections),function(collection,index){return [(index === 1)?_c('div',{key:("evolution-" + index),staticClass:"col-span-full row-auto md:row-span-2 md:col-span-5"},[_c('h1',{staticClass:"pt-0 pb-0",domProps:{"innerHTML":_vm._s(_vm.data.evolution.title)}}),_c('text-block',{staticClass:"pt-0 pb-2",attrs:{"data":_vm.data.evolution.content}}),(_vm.evolutionImage.length > 0)?_c('silent-box',{attrs:{"image":_vm.evolutionImage[0]}}):_vm._e(),(_vm.data.evolution.tags !== null)?_c('tags',{attrs:{"tags":_vm.data.evolution.tags}}):_vm._e()],1):_c('div',{key:index,class:[
        {'col-span-full row-auto md:row-span-1 md:col-span-8': (index % 4 == 0) },
        {'col-span-full row-auto md:row-span-2 md:col-span-5': (index % 4 == 1) },
        {'col-span-full row-auto md:row-span-1 md:col-span-3': (index % 4 == 2) },
        {'col-span-full row-auto md:row-span-1 md:col-span-3': (index % 4 == 3) } ]},[_c('router-link',{staticClass:"no-underline",attrs:{"to":{ name: 'Single', params: { type: collection.type, slug: collection.slug }}}},[(collection.featured.title)?_c('h1',{staticClass:"pt-0",domProps:{"innerHTML":_vm._s(collection.featured.title)}}):_c('h1',{staticClass:"pt-0",domProps:{"innerHTML":_vm._s(collection.title)}}),(collection.featured.image)?_c('image-block',{attrs:{"data":collection.featured,"figcaption":false}}):(collection.featured.video)?_c('video-block',{attrs:{"data":collection.featured}}):_vm._e(),(collection.featured.excerpt)?_c('text-block',{attrs:{"data":collection.featured.excerpt}}):_vm._e(),(collection.tags !== null)?_c('tags',{attrs:{"tags":collection.tags}}):_vm._e()],1)],1)]})],2):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }