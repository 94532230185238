import Vue from 'vue'
import Vuex from 'vuex'
import settings from './modules/settings'
import cart from './modules/cart'
import product from './modules/product'
import shipping from './modules/shipping'
import payment from './modules/payment'

import axios from 'axios'

Vue.use(Vuex)

const debug = false

const localStoragePlugin = store => {
  store.subscribe((mutation, { cart, shipping }) => {
    window.localStorage.setItem('strandbeest-shopping-cart', JSON.stringify(cart.items))
  })
}
const plugins = [localStoragePlugin]

export default new Vuex.Store({
  state: {
    mobile: false,
    layout: null,
    intro: null,
  },
  mutations: {
    setMobile (state, boolean) {
      state.mobile = boolean
    },
    setLayout (state, payload) {
      state.layout = payload
    },
    setIntro(state, payload) {
      state.intro = payload
    }
  },
  actions: {
    fetchIntroductions({commit}) {
      axios.get(`${process.env.VUE_APP_API_URL}/introductions`)
      .then(response => {
        commit('setIntro', response.data)
      })
      .catch(error => {
        console.log(error);
      })    
    },
  },
  getters: {
    introTexts(state) {
      return state.intro
    }
  },
  modules: {
    settings,
    product,
    cart,
    shipping,
    payment
  },
  plugins,
  strict: debug
})
