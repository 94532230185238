<template>
  <div class="home">
    <!-- <div class="pt-4">
      <h1 class="pt-0">On view</h1>
      <a
        href="https://www.strandbeest.com/news/strandbeests-the-next-generation"
      >
        <img src="@/assets/images/banner.jpg" alt="" />
      </a>
    </div> -->
    <div v-if="data !== null && collections.length > 0" class="grid grid-cols-8 gap-6 pt-2 lg:pt-5">
      <div v-if="data.introduction" class="col-span-full row-auto md:row-span-2 md:col-span-5">
        <h1 class="pt-0" v-html="data.introduction.title" />
        <video-block :data="data.introduction" />
        <text-block :data="data.introduction" />
        <ul class="flex flex-wrap pt-4">
          <li
            class="bg-black hover:bg-white text-white hover:text-black transition-colors rounded-full outline-none md:px-3 px-2 mr-2">
            <router-link :to="{ name: 'Collection', params: { type: 'strandbeest' }}">
              Genealogy
            </router-link>
          <li>
          <li
            class="bg-black hover:bg-white text-white hover:text-black transition-colors rounded-full outline-none md:px-3 px-2 mr-2">
            <router-link :to="{ name: 'Collection', params: { type: 'evolution' }}">
              Evolution
            </router-link>
          </li>
        </ul>
        <tags v-if="data.introduction.tags !== null" :tags="data.introduction.tags" />
      </div>

      <template v-for="(story, index) in stories">
        <div class="col-span-full row-auto md:col-span-3 md:row-span-1" :key="`story-${index}`">
          <router-link class="no-underline" :to="{
            name: 'Single',
            params: {
              type: 'news',
              slug: story.slug
            }
          }">
            <h1 v-if="story.featured.title" v-html="story.featured.title" class="pt-0" />
            <h1 v-else class="pt-0" v-html="story.title" />
            <image-block :data="story.featured" :figcaption="false" />
            <text-block :data="story.featured.excerpt" />
            <tags v-if="story.tags !== null" :tags="story.tags" />
          </router-link>
        </div>
      </template>

      <template v-for="(collection, index) in collections">
        <div class="col-span-full row-auto md:row-span-2 md:col-span-5" v-if="index === 1" :key="`evolution-${index}`">
          <h1 v-html="data.evolution.title" class="pt-0 pb-0" />
          <text-block class="pt-0 pb-2" :data="data.evolution.content" />
          <silent-box v-if="evolutionImage.length > 0" :image="evolutionImage[0]" />
          <!-- <image-block
            :data="data.evolution"
            :figcaption="false"
          /> -->
          <tags v-if="data.evolution.tags !== null" :tags="data.evolution.tags" />
        </div>
        <div v-else :class="[
          {'col-span-full row-auto md:row-span-1 md:col-span-8': (index % 4 == 0) },
          {'col-span-full row-auto md:row-span-2 md:col-span-5': (index % 4 == 1) },
          {'col-span-full row-auto md:row-span-1 md:col-span-3': (index % 4 == 2) },
          {'col-span-full row-auto md:row-span-1 md:col-span-3': (index % 4 == 3) },
        ]" :key="index">
          <router-link class="no-underline"
            :to="{ name: 'Single', params: { type: collection.type, slug: collection.slug }}">
            <h1 class="pt-0" v-if="collection.featured.title" v-html="collection.featured.title" />
            <h1 class="pt-0" v-else v-html="collection.title" />
            <image-block v-if="collection.featured.image" :data="collection.featured" :figcaption="false" />
            <video-block v-else-if="collection.featured.video" :data="collection.featured" />
            <text-block v-if="collection.featured.excerpt" :data="collection.featured.excerpt" />
            <tags v-if="collection.tags !== null" :tags="collection.tags" />
          </router-link>
        </div>
      </template>
    </div>
    <slot />
  </div>
</template>

<script>
import TextBlock from '@/components/blocks/TextBlock.vue'
import ImageBlock from '@/components/blocks/ImageBlock.vue'
import VideoBlock from '@/components/blocks/VideoBlock.vue'
import Tags from '@/components/Tags.vue'

export default {
  name: 'Home',
  metaInfo: {
    title: 'Home',
  },
  components: {
    TextBlock,
    ImageBlock,
    VideoBlock,
    Tags
  },
  data() {
    return {
      api: process.env.VUE_APP_API_URL,
      data: null,
      collections: [],
      stories: [],
      evolutionImage: [],
    }
  },
  mounted() {
    let types = ['evolutions', 'explains', 'fossils', 'strandbeests']

    this.$api
      .get('home')
      .then(response => {
        this.data = response.data

        types.forEach(type => {
          this.data[type].forEach(object => {
            if (type === 'evolutions') {
              object.type = 'evolution'
            } else if (type === 'strandbeests') {
              object.type = 'strandbeest'
            } else {
              object.type = type
            }
            this.collections.push(object)
          })
        })

        this.stories = this.data['stories']
        this.evolutionImage = [
          {
            src: this.api + this.data.evolution.image.url,
            description: this.data.evolution.image.caption
          }
        ]
      })
      .catch(error => {
        console.log(error);
      })
  },
}
</script>
