import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Unicon from 'vue-unicons'
import VueFormulate from '@braid/vue-formulate'
import VueSilentbox from 'vue-silentbox'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import VueMeta from 'vue-meta'
import { uniInstagram, uniFacebook, uniYoutube, uniShoppingCart } from 'vue-unicons/src/icons'
import api from './services/api'
import { currency } from '@/filters/currency'
import './assets/styles/index.css'

Vue.config.productionTip = false

Vue.prototype.$api = api

Unicon.add([uniInstagram, uniFacebook, uniYoutube, uniShoppingCart])
Vue.use(Unicon)

Vue.use(VueFormulate)
Vue.use(VueSilentbox)

// // Add a rule.
// extend('secret', {
//   validate: value => value === 'example',
//   message: 'This is not the magic word'
// });

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.filter('currency', currency)

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('titleize', function (slug) {
    let slugs = slug.split("-");
    return slugs.map(function(slug) {
      return slug.charAt(0).toUpperCase() + slug.substring(1).toLowerCase();
    }).join(' ');  
})

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#strandbeest')
