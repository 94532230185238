import _ from 'lodash'

function getShippingBetweenWeights(array, types, orderWeight) {
  let availableShipping = []

  types.forEach(type => {
    if (array[type]) {
      array[type].weights.map(item => {
        if (orderWeight >= item.startWeight && orderWeight < item.endWeight) {
          availableShipping.push({
            countryId: array.id,
            shippingId: item.id,
            type: 'shipping_fee',
            shippingType: type,
            quantity: 1,
            unitPrice: item.unitPrice,
            trackNTrace: item.trackNTrace
          })
        }
      })
    }
  });
  
  return availableShipping
}

export default {
  state: {
    items: JSON.parse(window.localStorage.getItem('strandbeest-shopping-cart') || '[]'),
    shippingCountry: null,
    // shipping: [],
  },
  getters: {
    availableShipping(state, getters, rootState) {
      const totalWeight = getters.totalWeight
      const countryCode = state.shippingCountry
      const productCount = getters.numberOfProductsInCart
      const country = rootState.shipping.countries.find(c => c.code == countryCode)

      let availableShipping = []

      if (totalWeight && countryCode && productCount && country) {
        if (productCount > 1) {
          // only parcel available
          let shippingTypes = ['parcel']
          availableShipping = getShippingBetweenWeights(country, shippingTypes, totalWeight)
        } else if (productCount == 1) {
          // parcel & letter
          let shippingTypes = ['parcel', 'letter']
          availableShipping = getShippingBetweenWeights(country, shippingTypes, totalWeight)
        }
      }

      return availableShipping
    },
    numberOfProductsInCart(state) {
      let number = 0
      state.items.map(cartItem => {
        if (cartItem.type !== 'shipping_fee') {
          number = number + cartItem.quantity
        }
      })
      return number
    },
    totalWeight(state) {
      let weight = 0
      state.items.map(cartItem => {
        if (cartItem.type === 'physical') weight += (cartItem.weight * cartItem.quantity)
      })
      return weight
    },
    subTotal(state) {
      return _.sumBy(state.items, (p) => {
        if (p.type === 'shipping_fee') {
          return 0
        } else {
          return p.quantity * p.unitPrice;
        }
      }).toFixed(2)
    },
    totals(state) {
      return _.sumBy(state.items, (p) => {
        return p.quantity * p.unitPrice
      }).toFixed(2)
    },
    shippingCountry(state, rootState) {
      const shippingInCart = state.items.find(item => item.type === 'shipping_fee')

      if (shippingInCart) {
        const shipping = rootState.countries.find(country => country.id === shippingInCart.id)
        if (shipping) {
          return shipping.code
        } else {
          return null
        }
      } else {
        return null
      }
    },
    cartProducts(state, getters, rootState) {
      return state.items.map(cartItem => {
        if (rootState.product.items.length > 0) {
          if (cartItem.type !== 'shipping_fee') {
            const product = rootState.product.items.find(product => product.id === cartItem.id)
            const image = product.featured.image
            return {
              id: product.id,
              type: product.type,
              image: image,
              name: product.title,
              unitPrice: product.unitPrice,
              quantity: cartItem.quantity
            }
          } else {
            const shipping = rootState.shipping.countries.find(country => country.id === cartItem.id)
            if (shipping) {
              return {
                id: shipping.id,
                type: shipping.type,
                unitPrice: shipping.unitPrice,
                quantity: cartItem.quantity
              }
            }
          }
        }
      })
    }
  },
  actions: {
    addProductToCart(context, product) {
      // find cart item
      const cartItem = context.state.items.find(item => item.id === product.id)
      // check if product is in cart
      if (!cartItem) {
        // add product to cart
        context.commit('pushProductToCart', product)
      } else {
        if (cartItem.quantity !== parseInt(product.stock)) {
          // increment product quantity
          context.commit('incrementItemQuantity', cartItem)
        }
      }
    },
    selectCountry(context, shipping) {
      // find country entry
      const shippingCountry = context.rootState.shipping.countries.find(country => country.code === shipping)
      // set country shipping code
      context.commit('setShippingCountry', shippingCountry.code)
    },
    updateProductQuantity(context, {cartItem, increment}) {
      let newQuantity
      const cartProduct = context.state.items.find(item => item.id === cartItem.id)
      const product = context.rootState.product.items.find(item => item.id === cartProduct.id);
      (!increment) ? newQuantity = cartItem.quantity - 1 : newQuantity = cartItem.quantity + 1;

      if (newQuantity < cartProduct.quantity) {
        // decrement
        if (newQuantity == 0) {
          // delete from cart
          context.commit('deleteProductFromCart', cartItem)
        } else {
          // remove quantity from cart
          context.commit('updateCart', { cartItem, quantity: parseInt(newQuantity) })
        }
      } else {
        // increment
        if (newQuantity == product.stock || parseInt(newQuantity) > parseInt(product.stock)) {
          // items in cart are the same as our stock, set to max
          context.commit('updateCart', { cartItem, quantity: product.stock })  
        } else {
          // add to cart
          context.commit('incrementItemQuantity', cartProduct)
        }
      }
    },
    removeFromCart({commit}, product) {
      // TODO: check if last product in cart, if so -> delete shipping
      commit('deleteProductFromCart', product)
    },
    emptyCart({commit}) {
      commit('deleteAllItemsFromCart')
    }
  },
  mutations: {
    pushProductToCart(state, product) {
      state.items.push({
        id: product.id,
        type: product.type,
        unitPrice: product.unitPrice,
        weight: product.weight,
        quantity: 1
      })
    },
    pushShippingToCart(state, country) {
      state.items.push({
        id: country.id,
        type: country.type,
        unitPrice: country.unitPrice,
        quantity: 1,
      })
    },
    setShippingCountry(state, country) {
      state.shippingCountry = country
    },
    removeShippingFromCart(state, country) {
      state.items = state.items.filter(p => p.id !== country.id)
    },
    updateCart(state, {cartItem, quantity}) {
      const record = state.items.find(p => p.id === cartItem.id)
      record.quantity = parseInt(quantity)
    },
    deleteProductFromCart(state, product) {
      state.items = state.items.filter(cartItem => cartItem.id !== product.id)
    },
    deleteAllItemsFromCart(state) {
      state.items = []
    },
    incrementItemQuantity(state, cartItem) {
      cartItem.quantity++
    },
    decrementProductInventory(state, product) {
      product.stock--
    },
  }
}