import axios from 'axios'

export default {
  getSettings(cb) {
    axios.get(`${process.env.VUE_APP_API_URL}/settings`)
    .then(response => {
      cb(response.data, null)
    })
    .catch(error => {
      cb(null, error)
    })
  },
}