import axios from 'axios';

export default {
  getProducts(cb) {
    axios.get(`${process.env.VUE_APP_API_URL}/products`)
    .then(response => {
      cb(response.data, null)
    })
    .catch(error => {
      cb(null, error)
    })
  },
  getPaymentMethods(cb) {
    axios.get(`${process.env.VUE_APP_API_URL}/methods`)
    .then(response => {
      cb(response.data, null)
    })
    .catch(error => {
      cb(null, error)
    })
  },
  getShippingCountries(cb) {
    axios.get(`${process.env.VUE_APP_API_URL}/countries`)
    .then(response => {
      cb(response.data, null)
    })
    .catch(error => {
      cb(null, error)
    })
  }
}