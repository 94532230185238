import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('@/views/Error.vue'),
    props: true
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('@/views/ProductList.vue'),
  },
  {
    path: '/shop/cart',
    name: 'Cart',
    component: () => import('@/views/Cart.vue'),
  },
  {
    path: '/shop/checkout',
    name: 'Checkout',
    component: () => import('@/views/Checkout.vue'),
  },
  {
    path: '/shop/order',
    name: 'Order',
    props: route => ({
      ordernr: route.query.ordernr,
      id: route.query.id
    }),
    component: () => import('@/views/Order.vue')
  },
  {
    path: '/shop/:slug',
    name: 'Product',
    component: () => import('@/views/Product.vue'),
  },
  {
    path: '/beach-session',
    name: 'SignUp',
    component: () => import('@/views/SignUp.vue')
  },
  {
    path: '/:type',
    name: 'Collection',
    component: () => import('@/views/Collection.vue'),
  },
  {
    path: '/:type/:slug',
    name: 'Single',
    component: () => import('@/views/Single.vue')
  },
  {
    path: '*',
    name: 'GeneralError',
    component: () => import('@/views/Error.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let layout
  let type = to.params.type

  if (type !== undefined) {
    if (type === 'stories' || type === 'news' || type === 'explains' || type === 'explains' || type === 'fossils') {
      layout = 'masonry'
    } else if (type === 'strandbeest') {
      layout = 'grid'
    } else if (type === 'evolution') {
      layout = 'filter-single'
    }
    store.commit('setLayout', layout)
    next()
  } else if (type === undefined) {
    next()
  }
})

export default router
