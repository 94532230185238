import settings from '@/services/settings'

export default {
  state: {
    settings: false
  },
  mutations: {
    setSettings(state, payload) {
      state.settings = payload
    }
  },
  actions: {
    fetchSettings({commit}) {
      settings.getSettings((settings, error) => {
        if (error) {
          throw error
        }
        commit('setSettings', settings)
      })
    }
  },
  getters: {
    settings(state) {
      return state.settings
    }
  }
}