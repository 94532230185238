import shop from '@/services/shop'

export default {
  state: {
    methods: [],
  },
  actions: {
    fetchPaymentMethods({commit}) {
      shop.getPaymentMethods((methods, error) => {
        if (error) {
          throw error
        }
        commit('setPaymentMethods', methods)
      })
    },
  },
  mutations: {
    setPaymentMethods(state, methods) {
      state.methods = methods
    }
  },
  getters: {
    paymentMethods(state) {
      return state.methods
    },
  }
}