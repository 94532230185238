<template>
  <ul 
    v-if="sortedTags"
    class="flex flex-wrap pt-4"
  >
    <li
      class="bg-black hover:bg-white text-white hover:text-black transition-colors rounded-full outline-none md:px-3 md:mb-3 px-2 mr-2 mb-3"
      v-for="(tag, index) in sortedTags"
      :key="index"
    >
      <router-link
        class="no-underline"
        v-if="tag.type !== 'explain'"
        :to="{ 
          name: (tag.type === 'shop' ? 'Product' : 'Single'), 
          params: { type: tag.type, slug: tag.slug }
        }"  
      >
        <span v-html="tag.title"></span>
      </router-link>
      <router-link
        v-else
        class="no-underline"
        :to="{ name: 'Collection', params: { type: 'explains' } }"
      >
        <span v-html="tag.title"></span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    tags: {
      type: Object,
      required: true
    }
  },
  name: 'Tags',
  data() {
    return {
      tagTypes: ['evolution', 'fossil', 'page', 'product', 'story', 'news', 'strandbeest', 'explain']
    }
  },
  computed: {
    sortedTags() {
      const test = Object.keys(this.tags)
        .filter(key => this.tagTypes.includes(key))
        .reduce((obj, key) => {
          obj[key] = this.tags[key];

            if (key === 'fossil') {
              obj[key].type = 'fossils'
            } else if (key === 'story') {
              obj[key].type = 'news'
            } else if (key === 'product') {
              obj[key].type = 'shop'
            } else if (key === 'news') {
              obj[key].type = 'news'
            } else {
              obj[key].type = key
            }

            return obj;
        }, {});

      return Object.entries(test).map(e => e[1])
    }
  }
}
</script>