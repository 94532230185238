import shop from '@/services/shop'

export default {
  state: {
    items: [],
    countries: [],
  },
  mutations: {
    setProducts(state, products) {
      state.items = products
    },
  },
  actions: {
    fetchProducts({commit}) {
      shop.getProducts((products, error) => {
        if (error) {
          throw error
        }
        commit('setProducts', products)
      })
    },
  },
  getters: {
    products(state) {
      return state.items
    },
  }
}