<template>
  <div>
    <span class="underline cursor-pointer" @click="$router.back()">« Previous</span>
    <span> | </span>
    <template v-if="$route.path === '/'">
      Home
    </template>
    <template v-else>
      <router-link :to="{ path: '/' }">Home</router-link>
    </template>

    <span v-for="(crumb, index) in crumbs" :key="index">
      <template v-if="crumb.to === '/page' || (crumb.to === $route.path && $route.path !== '/')">
        – {{ crumb.text | titleize }}
      </template>
      <template v-else>
        <template v-if="crumb.to === '/'">
          <router-link :to="crumb.to">{{ crumb.text | titleize }}</router-link>
        </template>
        <template v-else>
          – <router-link :to="crumb.to">{{ crumb.text | titleize }}</router-link>
        </template>
      </template>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  computed: {
    crumbs() {
      let pathArray = this.$route.path.split("/")
      pathArray.shift()
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text: path,
        });
        return breadcrumbArray;
      }, [])
      return breadcrumbs;
    }
  }
}
</script>