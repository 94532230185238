<template>
  <div
    class="
      max-w-6xl
      mx-auto
      px-4
      font-atlas-grotesk-light
      subpixel-antialiased
      min-h-screen
      flex flex-col
    "
  >
    <modal v-if="show && popUpEnabled" @show="toggleModal" />
    <router-link :to="{ name: 'Cart' }">
      <shopping-cart v-if="cartProductsLength > 0" />
    </router-link>
    <top-header />
    <breadcrumbs class="pt-14 md:pt-16" />
    <transition name="fade" mode="out-in">
      <router-view
        class="flex flex-col flex-1 justify-between"
        :key="$route.path"
      >
        <bottom-footer />
      </router-view>
    </transition>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import BottomFooter from "@/components/Footer.vue";

export default {
  name: "App",
  metaInfo: {
    titleTemplate: (titleChunk) => {
      return titleChunk ? `${titleChunk} - Strandbeest` : "Strandbeest";
    },
  },
  data: () => ({
    show: true,
  }),
  components: {
    TopHeader: () => import("@/components/header/Header.vue"),
    ShoppingCart: () => import("@/components/ShoppingCart.vue"),
    Modal: () => import("@/components/Modal.vue"),
    Breadcrumbs,
    BottomFooter,
  },
  created() {
    // Get settings
    this.$store.dispatch("fetchSettings");
    // Get products
    this.$store.dispatch("fetchProducts");
    // Get countries
    this.$store.dispatch("fetchCountries");
    // get introductions
    this.$store.dispatch("fetchIntroductions");

    this.calculateMobile();
    window.addEventListener("resize", this.calculateMobile);
  },
  computed: {
    cartProductsLength() {
      return this.$store.getters.numberOfProductsInCart;
    },
    settings() {
      return this.$store.getters.settings;
    },
    popUpEnabled() {
      return this.$store.getters.settings?.pop_up?.enable;
    },
  },
  methods: {
    calculateMobile() {
      window.innerWidth < 768
        ? this.$store.commit("setMobile", true)
        : this.$store.commit("setMobile", false);
    },
    toggleModal(payload) {
      if (payload) {
        this.show = !this.show;
      } else {
        this.show = payload;
      }
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-in;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active {
  transition-delay: 600ms;
}
</style>