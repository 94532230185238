<template>
  <figure
    :class="[{'relative left-1/2 right-1/2 -ml-48vw -mr-48vw max-w-96vw': data.full_width }]"
  >
    <img
      v-if="data.full_width || image.url"
      :src="api + image.url"
      :alt="image.alternativeText"
      :class="{ 'w-full': data.full_width }"
      @load="loaded"
    >
    <img
      v-else-if="image.formats.large"
      :src="api + image.formats.large.url"
      :alt="image.alternativeText"
      @load="loaded"
    >
    <img
      v-else-if="image.formats.medium"
      :src="api + image.formats.medium.url"
      :alt="image.alternativeText"
      @load="loaded"
    >
    <img
      v-else-if="image.formats.small"
      :src="api + image.formats.small.url"
      :alt="image.alternativeText"
      @load="loaded"
    >
    <figcaption :class="[{'container': data.full_width }, 'pt-2']" v-if="figcaption && image.caption">{{ image.caption }}</figcaption>
  </figure>
</template>

<script>
export default {
  name: 'ImageBlock',
  props: {
    data: Object,
    figcaption: {
      type: Boolean,
      default: true,
    }
  },
  data () {
    return {
      api: process.env.VUE_APP_API_URL,
      image: null,
    }
  },
  created() {
    if (this.data.images) {
      this.image = this.data.images[0]
    } else {
      this.image = this.data.image
    }
  },
  methods: {
    loaded() {
      this.$nextTick(() => {
        this.$emit('init')
      })
    }
  }
}
</script>