<template>
  <div 
    @click="play" 
    :class="[{'relative left-1/2 right-1/2 -ml-48vw -mr-48vw max-w-96vw': data.full_width },
    'flex relative items-center justify-center cursor-pointer']"
  >
    <div v-if="!playing && canPlay && !isSafari" class="absolute w-full h-full z-10 flex justify-center items-center">
      <img class="w-1/6" src="@/assets/svg/play-white.svg"/>
    </div>
    <figure class="w-full">
      <video 
        ref="video" 
        class="outline-none w-full"
        :preload="(isSafari) ? 'none' : 'metadata'"
        :poster="poster"
        @mouseover="mouseOver"
        @mouseleave="mouseLeave"
        @loadeddata="load"
        @canplay="canPlay = true"
        loop playsinline controls="true"
      >
        <source :src="`${api + data.video.url}#t=${time}`" type="video/mp4">
        <meta itemprop="name" :content="data.video.alternativeText">
        <meta itemprop="description" :content="data.video.caption"> 
        {{ data.video.alternativeText }}
      </video>
      <figcaption 
        v-if="data.video.caption"
        :class="[{'container': data.full_width}, 'pt-2']"
      >
        {{ data.video.caption }}
      </figcaption>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'VideoBlock',
  props: ['data'],
  data() {
    return {
      api: process.env.VUE_APP_API_URL,
      canPlay: false,
      playing: false,
      isSafari: null,
    }
  },
  mounted() {
    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },
  computed: {
    poster() {
      let url
      (this.data.poster) 
        ? url = this.api + this.data.poster.url
        : url = null

      return url
    },
    time() {
      let time
      (this.data.time) 
        ? time = this.data.time
        : time = 0

      return time
    }
  },
  methods: {
    load() {
      this.$emit('load')
    },
    play() {
      if (!this.playing && this.canPlay) {
        this.playing = true
        this.$refs.video.play()
        this.$nextTick(() => {
          this.$refs.video.play()
        })
      }
    },
    mouseLeave() {
      const video = this.$refs.video
      video.removeAttribute("controls", "controls")
    },
    mouseOver() {
      const video = this.$refs.video
      video.setAttribute("controls", "controls")
    }
  }
}
</script>